/* APP LEVEL STYLES */

body {
  font-family: Arial, Helvetica, sans-serif;
  margin: auto;
  margin-top: 8px;
}

.page-container {
  text-align: center;
}

.loading-container {
  text-align: center;
  padding-top: 20%;
}

h1 {
  padding-right: 10%;
  padding-left: 10%;
}

p {
  font-size: 1.10em;
  padding-right: 10%;
  padding-left: 10%;
}

#nsfw-toggle {
  border: solid 1px;
  border-color: darkgrey;
  border-radius: 4px;
  max-width: 192px;
  display: flex;
  flex-direction: column;
  margin-left:auto;
  margin-right:auto;
  margin-top:1.25rem;
  margin-bottom:1rem;
  padding-bottom: .5rem;
}

.clickable:hover {
  cursor: pointer;
}

.submitted-icon {
  color: green;
  vertical-align: bottom;
}

.waiting-icon {
  color: yellow;
  vertical-align: bottom;
}

.winning-icon {
  color: rgb(239, 208, 98);
  vertical-align: bottom;
  text-align: right;
}

.crown-icon {
  color: rgb(239, 208, 98);
  vertical-align: text-top;
  text-align: right;
}

.winner-column {
  padding: auto;
  width: 0px;
}

.no-action-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #141414;
  opacity: 0.55;
  overflow: hidden; /* disable scrolling*/
  z-index: 2; /* higher than all other items, but lower than 
  the confirm box*/
}

.info-container {
  max-width: 600px;
  align-self: center;
  margin: auto;
}

/* CARD STYLES */

.players-hand-container {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}

.winning-card-container {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submitting-player-text {
  color:rgb(148, 148, 148);
}

/* Playing Cards are 2.5in x 3.5in. 
That is 0.71 x 1 */
.playing-card {
  height: 200px;
  width: 143px;
  margin: auto;
  text-align: left;
}

.response-card-results {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-text {
  font-size: 1rem;
  padding-right: 7.5%;
  padding-left: 7.5%;
}

.solo-prompt-card {
  margin: auto;
}

/* RESPONSIVE CARD HAND CONTAINER MEDIA QUERIES */

/* Card width is 145px */
@media only screen and (max-width: 290px) {
  .two-card-hand {
    justify-content: start;
  }
}

@media only screen and (max-width: 435px) {
  .three-card-hand {
    justify-content: start;
  }
}

@media only screen and (max-width: 580px) {
  .four-card-hand {
    justify-content: start;
  }
}

@media only screen and (max-width: 725px) {
  .five-card-hand {
    justify-content: start;
  }
}

@media only screen and (max-width: 870px) {
  .six-card-hand {
    justify-content: start;
  }
}

@media only screen and (max-width: 1015px) {
  .seven-card-hand {
    justify-content: start;
  }
}

@media only screen and (max-width: 1160px) {
  .eight-card-hand {
    justify-content: start;
  }
}

@media only screen and (max-width: 1305px) {
  .nine-card-hand {
    justify-content: start;
  }
}

@media only screen and (max-width: 1450px) {
  .ten-card-hand {
    justify-content: start;
  }
}

@media only screen and (max-width: 1595px) {
  .eleven-card-hand {
    justify-content: start;
  }
}

@media only screen and (max-width: 1740px) {
  .twelve-card-hand {
    justify-content: start;
  }
}

/* QUIT GAME & END GAME DIALOGUE */

.confirm-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #141414;
  opacity: 0.85;
  overflow: hidden; /* disable scrolling*/
  z-index: 3; /* higher than all other items, but lower than 
    the confirm box*/
}

.cdd-container {
  flex-direction: column;
  position: fixed;
  background-color: rgb(207, 207, 204);
  min-width: 230px;
  max-width: 450px;
  width: 70%;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -75%);
  border-radius: 0.3rem;
  padding: 1rem;
  z-index: 5; /* Higher than the z-index of the background */
}

.confirmation-text {
  display: flex;
  color: rgb(20, 19, 19);
  margin: 0.5rem 0 1rem;
  text-align: center;
  align-items: center;
  line-height: 1.4rem;
  font-size: 1.1rem;
  justify-content: center;
}

.button-container {
  display: flex;
  margin-top: 1rem;
  justify-content: space-evenly;
}

.end-game {
  display: flex;
  background-color: #cc0000;
  color: white;
  min-height: 2.6rem;
  width: 45%;
  border: none;
  border-radius: 0.3rem;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  max-width: 125px;
  margin-left: 15px;
}

.resume-game {
  display: flex;
  background-color: #777d86;
  color: rgb(255, 255, 255);
  height: 2.6rem;
  width: 45%;
  border: none;
  border-radius: 0.3rem;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  max-width: 125px;
  margin-right: 15px;
}

.end-game:hover {
  background-color: red;
  cursor: pointer;
}

.resume-game:hover {
  background-color: #b2b2b2;
  cursor: pointer;
}

/* FOOTER */

.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: .50rem;
  padding-bottom: .50rem;
  text-align: center;
  color: #c3bdbd;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #0c0d0d;
}

.footer-image {
  cursor: pointer;
  height: 2.25rem;
}

.footer-text {
  margin: 0px;
  padding: 0px;
  font-size: 1.5rem;
}

@media only screen and (max-width: 520px) {
  .footer {
    padding-top: .5rem;
    padding-bottom: .5rem;
    text-align: center;
    color: #c3bdbd;
    font-size: 1.5rem;
  }

  .footer-text {
    margin: 0px;
    padding: 0px;
    font-size: 1.15rem;
  }

  .footer-image {
    height: 1.75rem;
  }
}

@media only screen and (max-width: 320px) {
  .footer {
    flex-direction: column;
  }
}

/* FEEDBACK */
.feedback-button {
  background-color: #000000;
  color: white;
  transform: rotate(270deg);
  translate: 31px;
  position: fixed;
  font-size: medium;
  bottom: 11%;
  right: 0;
  z-index: -1;
  padding-top: .35rem;
  padding-bottom: .35rem;
  border-radius: 10%;
  cursor: pointer;
}

.feedback-form {
  background-color: rgb(255, 255, 255);
  transition:cubic-bezier(0.755, 0.05, 0.855, 0.06);
  text-align: center;
  border: 1.5px solid rgb(255, 255, 255);
  flex-direction: column;
  position: fixed;
  bottom: 7%;
  width: 300px;
  height: 382px;
  right: 0;
  box-shadow:
  inset 0 0 0 4px rgb(101, 100, 100);
  border-radius: 1rem;
  padding: .85rem;
  z-index: 5; /* Higher than the z-index of the background */
}

.feedback-header-bg {
  background-color: #040404;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  height: 11%;
  width: 100%;
}

.feedback-header {
  font-size: 22px;
  color: white;
  margin-top: 10px;
}

.close-feedback-icon {
  color: white;
  cursor: pointer;
}

.close-feedback-icon-container {
  z-index: 7;
  position: absolute;
  top: 10px;
  right: 10px;
}

.feedback-helper-text {
  font-size: .85em;
  padding: 0;
  margin-top: 38px;
  z-index: inherit;
}

.feedback-results-text {
  padding: 0;
  margin-top: 100px;
}

.feedback-button-container {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
}

.submit-feedback {
  display: flex;
  background-color: #000000;
  color: white;
  min-height: 2.6rem;
  width: 35%;
  border: none;
  border-radius: 0.3rem;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  max-width: 125px;
  margin-left: 15px;
}

.close-feedback {
  display: flex;
  background-color: #ec1010;
  color: rgb(255, 255, 255);
  height: 2.6rem;
  width: 35%;
  border: none;
  border-radius: 0.3rem;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  max-width: 125px;
  margin-right: 15px;
}

.submit-feedback:hover {
  background-color: rgb(64, 63, 63);
  cursor: pointer;
}

.close-feedback:hover {
  background-color: #ff0000;
  cursor: pointer;
}

.close-feedback-complete {
  background-color: #000000;
  color: rgb(255, 255, 255);
  height: 2.6rem;
  width: 35%;
  border: none;
  border-radius: 0.3rem;
  font-size: 1rem;
  max-width: 125px;
  cursor: pointer;
  margin-top: 50px;
}

.close-feedback-complete:hover {
  background-color: #4c4c4c;
}
